import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { AngularFireModule } from 'angularfire2';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { MDBBootstrapModule } from './../../angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { CarEditComponent } from './+car/car-edit/car-edit.component';
import { CarDetailsComponent } from './+car/car-details/car-details.component';
import { AdminComponent } from './+admin/admin.component';
import { InventoryComponent } from './+inventory/inventory.component';

import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { AccountingComponent } from './+admin/accounting/accounting.component';
import {AccordionModule} from 'primeng/accordion';
import { OurpickModule } from './shared/components/ourpick/ourpick.module';
import { HttpClientModule } from '@angular/common/http';
import { NotSoldPipe } from './shared/not-sold.pipe';
// import { AgmCoreModule } from '@agm/core';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CarEditComponent,
    CarDetailsComponent,
    AdminComponent,
    InventoryComponent,
    AccountingComponent,
    NotSoldPipe,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyCXb0HNb-pfGPrduP-F-odnfi_lQoijsN4',
      authDomain: 'good-cars-4fdc4.firebaseapp.com',
      databaseURL: 'https://good-cars-4fdc4.firebaseio.com',
      projectId: 'good-cars-4fdc4',
      storageBucket: 'good-cars-4fdc4.appspot.com',
      messagingSenderId: '63331544557'
    }),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    AccordionModule,
    OurpickModule
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   apiKey: 'AIzaSyCxec6TkjXTaOzOLZzHyCAmK48lzpmz-kg'
    // }),

  ],
  providers: [
    AuthGuard,
    AuthService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public name = '';
  public email = '';
  public phone = '';
  public message = '';

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  sendEmail() {

    const url = `https://us-central1-good-cars-4fdc4.cloudfunctions.net/httpEmail`;

    const msg = {
      'to': 'sseissan@gmail.com',
      'cc': 'afshin9@gmail.com',
      'from': this.email,
      'subject': 'ABQ GOOD CARS WEBSITE',
      'body': `Name: ${this.name} - Phone: ${this.phone}  -  Message: ${this.message}`
    };


    this.http.post(url, msg)
      .pipe(
        retry(3),
        tap( // Log the result or error
          data => {
            alert('Thanks. We will be in touch shortly.');
            this.name = '';
            this.email = '';
            this.message = '';
            this.phone = '';
           },
          error => {
            alert('Error Sending Email. Make sure your email address is correct.');
          }
        ),
        catchError(this.handleError)
      ).subscribe(result => { });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CarService } from 'src/app/+car/car.service';
import { Car } from 'src/app/shared/models';

@Component({
  selector: 'app-ourpick',
  templateUrl: './ourpick.component.html',
  styleUrls: ['./ourpick.component.scss']
})
export class OurpickComponent implements OnInit {
  @Input() maxCars: number;
  allCars = new Array<Car>();
  pickedCars = new Array<Car>();
  pickedNumbers = [];

  constructor(private carService: CarService) {
    this.allCars = [];
    this.carService.getCars().pipe(
      tap((item: Car[]) => {
        item.forEach(element => {
          if (!!element.pictures && !element.sold) {
            element.route = `${element.year} ${element.make} ${element.model}`;
            element.route = element.route.replace(/ /g, '-');
            this.allCars.push(element);
          }
        });
      })
    ).subscribe(item2 => {
      if (this.maxCars === -1) {
        this.maxCars = this.allCars.length;
      }
      if (this.maxCars === this.allCars.length) {
        this.pickedCars = this.allCars;
      } else {
        for (let i = 0; i < this.maxCars; i++) {
          this.pickedCars.push(this.allCars[this.getUniqueRandomNumber()]);
        }
      }
    });
  }
  ngOnInit() {

  }

  getUniqueRandomNumber() {
    const num = Math.floor(Math.random() * this.allCars.length);
    if (this.pickedNumbers.indexOf(num) === -1) {
      this.pickedNumbers.push(num);
      return num;
    } else {
      this.getUniqueRandomNumber();
    }
  }
}

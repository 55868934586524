import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Car, Picture } from '../shared/models';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CarService } from '../+car/car.service';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from 'angularfire2/storage';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  items: Observable<Car[]>;
  item: Observable<Car>;
  modalForm: FormGroup;
  newCar: Car;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  cars: Observable<Car[]>;

  constructor(
    private storage: AngularFireStorage,
    public fb: FormBuilder,
    private carService: CarService) {

    this.modalForm = fb.group({
      make: ['', Validators.required],
      model: ['', [Validators.required]],
      year: ['', Validators.required],
      description: ['', Validators.required],
      vin: ['', Validators.required],
      color: ['', Validators.required],
      title: ['', Validators.required],
      mileage: ['', Validators.required],
      featured: ['', Validators.required],
      dateListed: ['', Validators.required],
      retailPrice: ['', Validators.required],
      salePrice: ['', Validators.required],
      financePrice: ['', Validators.required],
      soldAtPrice: ['', Validators.required],
      dateSold: ['', Validators.required],
      sold: ['', Validators.required],
      isFinance: ['', Validators.required],
      dealPrice: ['', Validators.required],
      deal: ['', Validators.required]
    });
  }
  ngOnInit() {
    this.newCar = new Car();
    this.getCars();
  }
  getCars(): any {
    this.cars = this.carService.getCars();
  }
  addNewCar(form) {
    this.newCar = new Car();
    form.show();
  }

  editCar(form, car: Car) {
    this.newCar = car;
    console.log(this.newCar);
    form.show();
  }
  logMe(key) {
    console.log(key);
  }

  saveCar(form) {
    const save = !!this.newCar.key
      ? this.carService.editCar(this.newCar)
      : this.carService.saveCar(this.newCar);

    save.then(form.hide());
  }

  vinEntered() {
    return this.newCar.vin.length > 5;
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = this.newCar.vin + '/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file, { customMetadata: { vin: this.newCar.vin } });

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        const pic = new Picture();
        fileRef.getDownloadURL().subscribe(data => {
          pic.path = data;
          pic.size = file.size;
          if (!!this.newCar.pictures) {
            this.newCar.pictures.push(pic);
          } else {
            this.newCar.pictures = new Array<Picture>();
            this.newCar.pictures.push(pic);
          }
        });

        this.downloadURL = fileRef.getDownloadURL();
      })
    )
      .subscribe();
  }
  deletePhoto(path) {
    this.newCar.pictures = this.newCar.pictures.filter((pic, index, arr) => {
      if (pic.path !== path) { return true; }
    });
  }
}

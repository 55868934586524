export class Car {
    key: string;
    make: string;
    model: string;
    color: string;
    year: string;
    title: string;
    mileage = -1;
    vin = '';
    prices = new Price();
    dateListed: Date;
    dateSold?: Date;
    isFinanced = false;
    description: string;
    sold = false;
    featured = false;
    makeItDeal = false;
    pictures: Picture[] = [];
    route: string;
}

export class Price {
    key: string;
    retail: Number;
    sale: Number;
    finance: Number;
    soldAt: Number;
    deal: Number;
}

export class Picture {
    key: string;
    fileName: string;
    path: string;
    size: string;
    ext: string;
    featured = false;
    caption: string;
    order: Number;
}

export class Journal {
    key: string;
    date: Date;
    vendor: string;
    part: string;
    note: string;
    car: string;
    price: Number;
    constructor(key: string,
        date: Date,
        vendor: string,
        part: string,
        note: string,
        car: string,
        price: Number,
    ) {
        this.key = key;
        this.date = date;
        this.vendor = vendor;
        this.part = part;
        this.note = note;
        this.car = car;
        this.price = price;
    }
}

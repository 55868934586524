import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurpickComponent } from './ourpick.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MDBBootstrapModule,
    RouterModule
  ],
  declarations: [
    OurpickComponent
  ],
  exports: [
    OurpickComponent
  ]
})
export class OurpickModule { }

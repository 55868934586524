import { Pipe, PipeTransform } from '@angular/core';
import { Car } from './models';

@Pipe({
  name: 'notSold'
})
export class NotSoldPipe implements PipeTransform {

  transform(cars: Car[]): any {
    return cars.filter(car => !car.sold);
  }

}

import { Component } from '@angular/core';
import { AngularFireStorage } from 'angularfire2/storage';
import { HostListener, ElementRef } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isScrolling = false;
  hide = false;

  constructor(
    private afStorage: AngularFireStorage,
    public authService: AuthService,
    public el: ElementRef,
  ) {
    localStorage.removeItem('firebase:previous_websocket_failure');
  }

  // uploadProgress = 0;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop;
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= 600) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }

  }

  onActivate(event) {

  }
  onDeactivate(event) {

  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CarDetailsComponent } from './+car/car-details/car-details.component';
import { AdminComponent } from './+admin/admin.component';
import { InventoryComponent } from './+inventory/inventory.component';
import { AuthGuard } from './auth/auth.guard';
import { AccountingComponent } from './+admin/accounting/accounting.component';

const routes: Routes =
  [
    { path: '', component: HomeComponent },
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] }, // loadChildren: './+admin/admin.module#AdminModule' },
    { path: 'accounting', component: AccountingComponent, canActivate: [AuthGuard] },
    { path: 'inventory', component: InventoryComponent}, // loadChildren: './+inventory/inventory.module#InventoryModule' },
    { path: 'cardetails/:carModel', component: CarDetailsComponent },
    { path: '**', redirectTo: '',  pathMatch: 'full' }
  ];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }

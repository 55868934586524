import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CarService } from '../car.service';
import { AngularFireObject } from 'angularfire2/database';
import { Car } from 'src/app/shared/models';

@Component({
  selector: 'app-car-details',
  templateUrl: './car-details.component.html',
  styleUrls: ['./car-details.component.scss']
})
export class CarDetailsComponent implements OnInit {
  carKey: string;
  car: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private carService: CarService) { }


  ngOnInit() {
    this.carKey = this.activatedRoute.snapshot.fragment;
    this.getCar();
  }
  getCar() {
    this.car = this.carService.getCar(this.carKey).valueChanges().subscribe(data => this.car = data);
  }
}

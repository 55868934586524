import { Component, OnInit } from '@angular/core';
import { Journal } from '../../shared/models';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {
  public journal: Journal;
  public entries: Journal[];
  public isWorking: boolean;
  constructor() { }

  ngOnInit() {
    this.journal = new Journal('', null, '', '', '', '', -1);
    this.entries = [];
    this.entries.push(new Journal('1', new Date(), 'eBay', 'Hood', 'A Black Hood', 'Ford Fiesta 2016', 45));
    this.entries.push(new Journal('2', new Date(), 'Amazon', 'Paint', 'Brand A', 'Ford Focus 2014', 60));
    this.entries.push(new Journal('3', new Date(), 'eBay', 'Passenger Door', 'From 2015 model', 'Subaru Impreza', 120));

  }
  logThis() {
    console.log(this.entries);
  }

  check() {
    this.isWorking = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from 'angularfire2/firestore';
import { Car } from '../shared/models';
import { Observable } from 'rxjs';
import { CarService } from '../+car/car.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  private itemsCollection: AngularFirestoreCollection<Car>;
  cars: Observable<Car[]>;
  cleanCars = new Array<Car>();
  constructor(private carService: CarService) {
    //    this.itemsCollection = this.store.collection<Car>('cars');
    //  this.cars = this.itemsCollection.valueChanges();
    this.carService.getCars().subscribe(item => {
      this.cleanCars = [];
      item.forEach(element => {
        if (!!element.pictures && !element.sold) {
          element.route = `${element.year} ${element.make} ${element.model}`;
          element.route = element.route.replace(/ /g, '-');
          this.cleanCars.push(element);
        }
      });
    });
  }

  ngOnInit() {
  }

}

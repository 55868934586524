import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AngularFireStorage } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Car, Picture } from '../shared/models';

// import 'rxjs/add/observable/from';
// import 'rxjs/add/operator/catch';

// private itemDoc: AngularFirestoreDocument<Car>;
// private itemsCollection: AngularFirestoreCollection<Car>;

// this.itemDoc = afs.doc<Car>('cars/1');
// this.item = this.itemDoc.valueChanges();

// this.itemsCollection = afs.collection<Car>('cars');
// this.items = this.itemsCollection.valueChanges();

@Injectable({
  providedIn: 'root'
})
export class CarService {
  cars$: AngularFireList<Car>;

  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage) {
    this.cars$ = this.db.list(`cars`);
  }

  // uploadFile(event) {
  //   const file = event.target.files[0];
  //   const filePath = this.newCar.vin + '/' + file.name;
  //   const fileRef = this.storage.ref(filePath);
  //   const task = this.storage.upload(filePath, file, { customMetadata: { vin: this.newCar.vin } });

  //   // observe percentage changes
  //   this.uploadPercent = task.percentageChanges();
  //   // get notified when the download URL is available
  //   task.snapshotChanges().pipe(
  //     finalize(() => {
  //       const pic = new Picture();
  //       fileRef.getDownloadURL().subscribe(data => pic.path = data);
  //       pic.featured = true;
  //       pic.size = file.size;
  //       this.newCar.pictures.push(pic);
  //       this.downloadURL = fileRef.getDownloadURL();
  //     })
  //   )
  //     .subscribe();
  // }

  getCar(carKey: string) {
    return this.db.object(`cars/${carKey}`);
  }

  getCars() {
    return this.cars$.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      )
    );
    // .subscribe(items => {
    //   //console.log(items);
    //   return items.map(item => item.key);
    // });
   // return this.cars$.valueChanges();
  }

  saveCar(car: Car) {
    return this.cars$.push(car)
      .then(_ => console.log('new car success'));
    // .catch(error => console.log(error));
  }

  editCar(car: Car) {
    console.log(car);
    return this.cars$.update(car.key, car)
      .then(_ => console.log('Edit Car success'))
      .catch(error => console.log(error));
  }

  removeCar(car) {
    return this.cars$.remove(car.$key)
      .then(_ => console.log('success'))
      .catch(error => console.log(error));
  }

  private errorHandler(error) {
    console.log(error);
    return Observable.throw(error.message);
  }
}
